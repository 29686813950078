<template>
    <step-settings-wrapper title="Data Capture" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Data Capture
                </div>
                <div class="for-xs float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="enabled" :value="preset.dataCaptureSettings.enabled" v-model="preset.dataCaptureSettings.enabled"></toggle-button>
                </div>
            </div>

            <h3 class="text-3xl uppercase font-bold tracking-wider pt-6">Data Fields</h3>

            <div class="flex flex-wrap -mx-5">
                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-2 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Title
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="titleEnabled" :value="preset.dataCaptureSettings.titleEnabled" v-model="preset.dataCaptureSettings.titleEnabled"></toggle-button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="flex flex-wrap -mx-5">

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-2 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            First Name
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="firstNameEnabled" :value="preset.dataCaptureSettings.firstNameEnabled" v-model="preset.dataCaptureSettings.firstNameEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-2 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Last Name
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="lastNameEnabled" :value="preset.dataCaptureSettings.lastNameEnabled" v-model="preset.dataCaptureSettings.lastNameEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

            </div>

            <div class="flex flex-wrap -mx-5">

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-3 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Email
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="emailEnabled" :value="preset.dataCaptureSettings.emailEnabled" v-model="preset.dataCaptureSettings.emailEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-3 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Phone No.
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="phoneEnabled" :value="preset.dataCaptureSettings.phoneEnabled" v-model="preset.dataCaptureSettings.phoneEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

            </div>

            <div class="flex flex-wrap -mx-5">

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-3 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Postcode
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="postCodeEnabled" :value="preset.dataCaptureSettings.postCodeEnabled" v-model="preset.dataCaptureSettings.postCodeEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

                <div class="w-full md:w-1/2 px-5">

                    <div class="pb-2 pt-3 border-b border-gray-custom-3 clearfix">
                        <div class="float-left text-xl uppercase font-bold tracking-wider custom-line-height-42px">
                            Date of Birth
                        </div>
                        <div class="float-right pt-1">
                            <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="birthDateEnabled" :value="preset.dataCaptureSettings.dateOfBirthEnabled" v-model="preset.dataCaptureSettings.dateOfBirthEnabled"></toggle-button>
                        </div>
                    </div>

                </div>

            </div>

            <div class="pb-2 pt-6 clearfix" :class="{'border-b border-gray-custom-3' : !preset.dataCaptureSettings.marketingOptinEnabled}">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Marketing Opt In
                </div>
                <div class="for-xs float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="marketingOptinEnabled" :value="preset.dataCaptureSettings.marketingOptinEnabled" v-model="preset.dataCaptureSettings.marketingOptinEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 border-b border-gray-custom-3" v-if="preset.dataCaptureSettings.marketingOptinEnabled">
                <div class="font-work-sans">
                    <textarea name="marketingOptinText" id="marketing_optin_text" class="bg-transparent w-full p-1 outline-none border border-gray-custom-2 font-work-sans-medium tracking-wider text-xl h-32" v-model="preset.dataCaptureSettings.marketingOptinText" placeholder="Marketing Opt In Message"></textarea>
                </div>
            </div>

            <div class="pb-2 pt-5">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Capture Screen Message
                </div>
                <div class="relative font-work-sans mt-4">
                    <label for="message_title" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="commentAltIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> Set a capture screen message title...</label>
                    <input type="text" name="messageTitle" id="message_title" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.dataCaptureSettings.messageTitle" />
                </div>
            </div>

            <div class="pb-2 pt-8">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Terms &amp; Conditions
                </div>
                <div class="relative font-work-sans mt-5">
                    <div class="font-work-sans">
                        <textarea name="termsAndConditions" id="terms_and_conditions" class="bg-transparent w-full p-1 outline-none border border-gray-custom-2 font-work-sans-medium tracking-wider text-xl h-32" v-model="preset.dataCaptureSettings.termsAndConditions" placeholder="Legals for people to agree too..."></textarea>
                    </div>
                </div>
            </div>

            <div class="pb-2 pt-5">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Privacy Policy URL
                </div>
                <div class="relative font-work-sans mt-4">
                    <label for="privacy_policy_url" class="text-xs sm:text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="externalLinkIcon" class="text-lg pr-1 align-text-top"></font-awesome-icon> Set a link to privacy policy...</label>
                    <input type="text" name="privacyPolicyURL" id="privacy_policy_url" class="bg-transparent w-full pb-1 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.dataCaptureSettings.client.privacyPolicyURL" />
                </div>
            </div>

            <div class="pb-5 pt-5">
                <div class="text-3xl uppercase font-bold tracking-wider">
                    Logo
                </div>
                <div class="w-48 md:inline-block md:w-1/3 md:max-w-full mt-5 md:align-top">
                    <file-select-upload allowedTypes="image/*" @input="previewLogo" ref="fileUpload"></file-select-upload>
                    <button type="button" class="pb-1 pt-3 pb-r bg-white text-black font-dense uppercase tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight outline-none w-full mt-5" v-if="(preset.dataCaptureSettings && preset.dataCaptureSettings.logo && removeLogo !== true) || newLogoSet" @click="removeLogoImage">
                        Remove
                    </button>
                </div>
                <div class="md:inline-block md:w-2/3 mt-5 md:pl-3" ref="logoImagePreviewWrapper">
                    <img :src="api_url+'/media/'+preset.dataCaptureSettings.logo" alt="" v-if="preset.dataCaptureSettings && preset.dataCaptureSettings.logo" />
                    <img alt="" src="" v-else />
                </div>
            </div>

        </div>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue'
    import { ToggleButton } from 'vue-js-toggle-button'
    import { faCommentAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
    import FileSelectUpload from "@/components/FileSelectUpload.vue";

    export default {
        name: 'DataCaptureSettings',
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        data: function() {
            return {
                beforeEditing: {},
                api_url: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                removeLogo: false,
                newLogoSet: false
            }
        },
        components: {
            StepSettingsWrapper,
            ToggleButton,
            FileSelectUpload
        },
        destroyed: function() {
            this.checkFieldsBeforeDestroy();
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.dataCaptureSettings);

            this.checkFieldsOnMounted();

            if( this.preset.dataCaptureSettings.messageTitle !== '' ) {
                document.getElementById('message_title').previousElementSibling.style.top = '-22px';
            }

            if( this.preset.dataCaptureSettings.client.privacyPolicyURL !== '' ) {
                document.getElementById('privacy_policy_url').previousElementSibling.style.top = '-22px';
            }
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-22px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                }
            },
            onClose: function(toRoute) {
                //this.preset.dataCaptureSettings = Object.assign({}, this.beforeEditing);
                this.checkFieldsBeforeDestroy();
                let filesSelected = this.$refs.fileUpload.getTotalFiles();
                this.$emit('close', 'dataCaptureSettings', this.beforeEditing, filesSelected, toRoute);
                this.checkFieldsOnMounted();
            },
            onSave: async function() {
                const result = await this.$refs.fileUpload.saveFiles();
                if( result !== false ) {
                    this.preset.dataCaptureSettings.removeLogo = this.removeLogo;
                    this.preset.dataCaptureSettings.logoImageS3Key = result;
                    this.$emit('save', 'dataCaptureSettings');
                }
            },
            previewLogo: function(files) {
                this.$refs.logoImagePreviewWrapper.getElementsByTagName('img')[0].src = URL.createObjectURL(files[0]);
                this.newLogoSet = true;
            },
            removeLogoImage: function() {
                this.$refs.logoImagePreviewWrapper.getElementsByTagName('img')[0].src = '';
                this.removeLogo = true;
                this.newLogoSet = false;
                this.$refs.fileUpload.removeFiles(0);
            },
            checkFieldsBeforeDestroy: function() {
                if( this.preset.dataCaptureSettings.messageTitle === '' ) {
                    this.preset.dataCaptureSettings.messageTitle = 'JOIN THE BOOTHCLUB COMMUNITY';
                }
                if( this.preset.dataCaptureSettings.marketingOptinText === '' ) {
                    this.preset.dataCaptureSettings.marketingOptinText = 'Opt in to receive marketing emails from Boothclub Ltd';
                }
                if( this.preset.dataCaptureSettings.termsAndConditions === '' ) {
                    this.preset.dataCaptureSettings.termsAndConditions = 'By participating, I understand and accept that my personal data will be sent to Megabooth LTD in the UK.';
                }
            },
            checkFieldsOnMounted: function() {

                if( this.preset.dataCaptureSettings.messageTitle === 'JOIN THE BOOTHCLUB COMMUNITY' ) {
                    this.preset.dataCaptureSettings.messageTitle = '';
                }

                if( this.preset.dataCaptureSettings.marketingOptinText === 'Opt in to receive marketing emails from Boothclub Ltd' ) {
                    this.preset.dataCaptureSettings.marketingOptinText = '';
                }

                if( this.preset.dataCaptureSettings.termsAndConditions === 'By participating, I understand and accept that my personal data will be sent to Megabooth LTD in the UK.' ) {
                    this.preset.dataCaptureSettings.termsAndConditions = '';
                }
            }
        },
        computed: {
            commentAltIcon: function() {
                return faCommentAlt;
            },
            externalLinkIcon: function() {
                return faExternalLinkAlt;
            }
        }
    }
</script>

<style scoped>
    label {
        transition: top 0.2s ease-out;
    }
    @media (max-width: 374px) {
        .for-xs {
            float: none;
            clear: both;
            position: relative;
            left: 40px;
        }
        .text-xs {
            font-size: 9px;
        }
    }
</style>